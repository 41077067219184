import RenderPdf from "./renderPdf/RenderPdf";

//isReading todo
const Actions = ({inputRef})=>{

    return (
        <>
          {/* <RenderPdf /> */}
          <h6>render the actions</h6>

        </>
    );
}

export default Actions;